import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  styled,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as UsersIcon } from "../../assets/users.svg";
import { ReactComponent as GlobalIcon } from "../../assets/global.svg";
import CallMadeIcon from "@mui/icons-material/CallMade";
import TrainingTable from "../Tables/TrainingTable";
import AddTraining from "../Modals/AddTraining";
import { setLimit, setPage } from "../../features/mod/modSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCoaches,
  getAllLevelDetails,
  getAllTrainings
} from "../../features/training/trainingSlice";
import {
  selectAvailableCoaches,
  selectLevelDetails
} from "../../features/training/trainingSelectors";
import getInitials from "../../utils/getInitials";
import useSignedUrl from "../../utils/useSignedUrl";
import { deleteTraining, updateCommunityLink } from "../../api/trainingApi";
import { Close, Help } from "@mui/icons-material";
import FAQModal from "../Modals/FAQModal";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    fontSize: "14px",
    background: "#FCFCFC",
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#D1D5DB"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D1D5DB"
    }
  }
}));

const ImageItem = ({ img }) => {
  const imgSrc = useSignedUrl(
    img,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );

  return (
    <Box
      component="img"
      height={40}
      borderRadius="50%"
      width={40}
      src={imgSrc}
      alt="avatar"
    />
  );
};

const Training = ({ setIsAddingTraining, isAddingTraining }) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [scheduleDate, setScheduleDate] = React.useState("");
  const [editData, setEditData] = React.useState(null);
  const [currentCoach, setCurrentCoach] = React.useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(null);
  const [editLinkModal, setEditLinkModal] = useState(null);
  const [selectedLevel, setSelectedLevel] = React.useState("level1");
  const availableCoaches = useSelector(selectAvailableCoaches) || [];
  const availableLevels = useSelector(selectLevelDetails) || [];
  const dispatch = useDispatch();
  const [openFAQModal, setOpenFAQModal] = useState();
  useEffect(() => {
    if (!searchValue || searchValue?.length > 3)
      dispatch(
        getAllTrainings({
          search: searchValue,
          level: selectedLevel
        })
      );
  }, [
    dispatch,
    searchValue,
    selectedLevel,
    confirmDeleteModal,
    isAddingTraining,
    currentCoach
  ]);

  useEffect(() => {
    dispatch(getAllCoaches());
  }, [dispatch]);

  useEffect(() => {
    if (!isAddingTraining) dispatch(getAllLevelDetails(currentCoach));
  }, [isAddingTraining, dispatch, currentCoach]);

  const handleDelete = async (id) => {
    await deleteTraining(id);
    setConfirmDeleteModal(null);
  };

  return !isAddingTraining ? (
    <Box>
      <Box
        display="flex"
        maxHeight="48px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gap={2} alignItems="center">
          <TextField
            variant="outlined"
            placeholder="Search..."
            size="small"
            fullWidth
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ height: "17px" }} />
                  </InputAdornment>
                )
              }
            }}
            sx={{
              "& .MuiInputBase-root": {
                padding: "3px 11px",
                height: "100%"
              },
              width: "387px",
              fontSize: "14px",
              background: "#FFFFFF",
              borderRadius: 2
            }}
          />
        </Box>
        <Box
          display="flex"
          gap={2}
          height="100%"
          minWidth={"35%"}
          alignItems="center"
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select coach</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentCoach || ""}
              label="Select coach"
              onChange={(e) => {
                setCurrentCoach(e.target.value);
              }}
            >
              {availableCoaches?.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  <Box display="flex" gap="10px" alignItems="center">
                    {item.profilePic ? (
                      <ImageItem img={item.profilePic} />
                    ) : (
                      <Avatar sx={{ width: 40, height: 40 }}>
                        {getInitials(item.name)}
                      </Avatar>
                    )}
                    <Typography fontSize="14px" fontWeight={400}>
                      {item.name}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <Button
              sx={{
                fontSize: "14px",
                padding: "16px 24px",
                background: "#38B65B",
                border: "1px solid #38B65B",
                color: "#fff",
                fontWeight: 700,
                minWidth: 150,
                textTransform: "none",
                height: "48px" // Ensure button height matches others
              }}
              onClick={() => {
                setIsAddingTraining(true);
                setScheduleDate("");
                setEditData(null);
                dispatch(setPage(0));
                dispatch(setLimit(10));
              }}
              variant="outlined"
            >
              Add Content
            </Button>
          </Box>
        </Box>
      </Box>

      <Box mt={7} mb={4} display="flex" gap={4} color="#FCFCFC">
        {availableLevels?.map(
          ({ levelName: level, totalUsers, title, link, faq }, index) => (
            <Box width={"33%"}>
              <Button
                key={index}
                fullWidth
                onClick={(e) => {
                  setSelectedLevel(level);
                }}
                sx={{
                  border: "1px solid #D1D5DB",
                  borderRadius: "16px",
                  color: selectedLevel === level ? "#FCFCFC" : "#6B7280", // Set to black if not matching
                  backgroundColor:
                    selectedLevel === level ? "#38B65B" : "#FCFCFC"
                }}
              >
                <Box width="100%" ml={2.5} mr={1} mt={2} mb={2}>
                  <Box
                    display="flex"
                    gap="16px"
                    textTransform="none"
                    alignItems="center"
                  >
                    <Box
                      display={"flex"}
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography
                          fontSize="20px"
                          fontWeight="bold"
                          color={
                            selectedLevel === level ? "#FCFCFC" : "#4B5563"
                          }
                        >
                          {title}
                        </Typography>
                      </Box>
                      <Box display={"flex"} gap="10px" alignItems={"center"}>
                        <Box
                          sx={{
                            height: "18px",
                            background: "#FCFCFC",
                            padding: "0 8px",
                            borderRadius: "10px"
                          }}
                        >
                          {selectedLevel === level && (
                            <Typography
                              fontSize="12px"
                              color="#38B65B"
                              fontWeight="bold"
                            >
                              Selected
                            </Typography>
                          )}
                        </Box>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditLinkModal({ link, level });
                          }}
                          sx={{
                            padding: 0,
                            height: 30,
                            width: 30,
                            border: `1px solid ${
                              selectedLevel === level ? "#fff" : "#9CA3AF"
                            }`,
                            borderRadius: "5px",
                            svg: {
                              path: {
                                fill:
                                  selectedLevel === level ? "#fff" : "#6B7280"
                              }
                            }
                          }}
                        >
                          <EditIcon height={17} width={17} fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={2} display="flex" gap="15px" alignItems="center">
                    <IconButton
                      onClick={(e) => e.stopPropagation()}
                      sx={{
                        padding: 0,
                        height: 30,
                        width: 30,
                        border: `1px solid ${
                          selectedLevel === level ? "#fff" : "#9CA3AF"
                        }`,
                        borderRadius: "5px",
                        svg: {
                          path: {
                            fill: selectedLevel === level ? "#fff" : "#6B7280"
                          }
                        }
                      }}
                    >
                      <UsersIcon height={17} width={17} fontSize="small" />
                    </IconButton>
                    <Typography
                      textTransform="none"
                      fontSize="14px"
                      color={selectedLevel === level ? "#FCFCFC" : "#6B7280"}
                    >
                      {totalUsers} users
                    </Typography>
                  </Box>
                  <Box mt={1} display="flex" gap="15px" alignItems="center">
                    <IconButton
                      onClick={(e) => e.stopPropagation()}
                      sx={{
                        padding: 0,
                        height: 30,
                        width: 30,
                        border: `1px solid ${
                          selectedLevel === level ? "#FCFCFC" : "#9CA3AF"
                        }`,
                        borderRadius: "5px",
                        svg: {
                          path: {
                            stroke:
                              selectedLevel === level ? "#FCFCFC" : "#9CA3AF"
                          }
                        }
                      }}
                    >
                      <GlobalIcon height={17} width={17} fontSize="small" />
                    </IconButton>
                    {link && (
                      <>
                        <Box>
                          <Tooltip title={link} arrow>
                            <Typography
                              textTransform="none"
                              sx={{
                                textDecoration: "underline",
                                fontSize: "14px",
                                maxWidth: "150px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                                color:
                                  selectedLevel === level
                                    ? "#FCFCFC"
                                    : "#368AD0"
                              }}
                            >
                              {link}
                            </Typography>
                          </Tooltip>
                        </Box>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            const url = link.startsWith("http")
                              ? link
                              : `http://${link}`;
                            window.open(url, "_blank");
                          }}
                          sx={{
                            padding: 0,
                            height: 20,
                            width: 20,
                            border: "1px solid #fff",
                            background:
                              selectedLevel === level ? "#FCFCFC" : "#368AD0",
                            borderRadius: "5px"
                          }}
                        >
                          <CallMadeIcon
                            sx={{
                              height: 16,
                              width: 16,
                              color:
                                selectedLevel === level ? "#38B65B" : "#FCFCFC"
                            }}
                          />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Box>
              </Button>
              <Box
                mt={1}
                display="flex"
                gap="15px"
                justifyContent={"center"}
                alignItems="center"
                sx={{
                  "&:hover": {
                    cursor: "pointer"
                  },
                  border: "1px solid #D1D5DB",
                  width: "100%",
                  borderRadius: "6px",
                  background: "#FCFCFC"
                }}
                onClick={() => setOpenFAQModal({ level, faq })}
              >
                <IconButton
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    padding: 0,
                    height: 30,
                    width: 30
                  }}
                >
                  <Help
                    height={17}
                    width={17}
                    fontSize="small"
                    color="#6B7280"
                  />
                </IconButton>
                <Typography
                  sx={{ userSelect: "none" }}
                  textTransform="none"
                  fontSize="14px"
                  color={"#6B7280"}
                >
                  FAQ Page
                </Typography>
              </Box>
            </Box>
          )
        )}
      </Box>
      <FAQModal
        levelName={openFAQModal?.level}
        userId={currentCoach}
        htmlValue={openFAQModal?.faq}
        open={openFAQModal?.level}
        onClose={() => setOpenFAQModal()}
      />
      <TrainingTable
        searchValue={searchValue}
        isAddingTraining={isAddingTraining}
        setIsAddingTraining={setIsAddingTraining}
        setEditData={setEditData}
        setConfirmDeleteModal={setConfirmDeleteModal}
      />
      <Modal open={!!editLinkModal} onClose={() => setEditLinkModal(null)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            width: { xs: "90%", sm: "380px" } // Responsive width
          }}
        >
          {/* Close button */}
          <Box py={2} px={1} display="flex" alignItems="center">
            <Typography
              width="100%"
              textAlign="end"
              color="#1F2937"
              fontWeight={600}
              fontSize={24}
            >
              Edit Link
            </Typography>
            <Box display="flex" width={"70%"} justifyContent="flex-end">
              <IconButton onClick={() => setEditLinkModal(null)}>
                <Close color="#1F2937" />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box p={2} pb={0}>
            <Typography fontSize={"13px"} fontWeight={"bold"}>
              Add Link for Community Chat
            </Typography>
            <StyledTextFieldContainer>
              <StyledTextField
                fullWidth
                variant="outlined"
                name="title"
                placeholder="Enter community link"
                value={editLinkModal?.link || ""}
                onChange={(e) =>
                  setEditLinkModal({ ...editLinkModal, link: e.target.value })
                }
                required
                margin="normal"
              />
            </StyledTextFieldContainer>
          </Box>

          {/* Action buttons */}
          <Box p={2}>
            <Button
              fullWidth
              variant="outlined"
              onClick={async () => {
                if (editLinkModal?.link) {
                  await updateCommunityLink(
                    editLinkModal?.link,
                    editLinkModal?.level,
                    currentCoach
                  );
                }
                setEditLinkModal(null);
                await dispatch(getAllLevelDetails(currentCoach));
              }}
              sx={{
                flex: 1,
                padding: "10px 0",
                background: "#38B65B",
                border: "none",
                textTransform: "none",
                color: "#FCFCFC",
                "&:disabled": {
                  backgroundColor: "#D7F0DE",
                  color: "#9CDBAD",
                  pointerEvents: "none"
                }
              }}
              disabled={!editLinkModal?.link}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={!!confirmDeleteModal}
        onClose={() => setConfirmDeleteModal(null)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            width: { xs: "90%", sm: "550px" } // Responsive width
          }}
        >
          {/* Close button */}
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setConfirmDeleteModal(null)}>
              <Close />
            </IconButton>
          </Box>

          {/* Modal title */}
          <Box mb={3} px={2} textAlign="center">
            <Typography fontSize={"24px"} fontWeight={600}>
              Are you sure you want to delete?
            </Typography>
            <Typography fontSize={"14px"} color="text.secondary" mt={1}>
              This change cannot be undone and may impact users.
            </Typography>
          </Box>

          {/* Action buttons */}
          <Box display="flex" justifyContent="space-between" px={2} gap={2}>
            {/* Cancel Button */}
            <Button
              variant="outlined"
              onClick={() => setConfirmDeleteModal(null)}
              sx={{
                flex: 1,
                padding: "10px 0",
                borderColor: "grey.400",
                color: "grey.600",
                "&:hover": {
                  backgroundColor: "grey.100",
                  borderColor: "grey.500"
                }
              }}
            >
              Cancel
            </Button>

            {/* Delete Button */}
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete(confirmDeleteModal)} // Assuming you have this delete function
              sx={{
                flex: 1,
                padding: "10px 0",
                color: "white",
                background: "#E94545",
                "&:hover": {
                  backgroundColor: "error.dark"
                }
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  ) : (
    <AddTraining
      scheduleDate={scheduleDate}
      setIsAddingTraining={setIsAddingTraining}
      editData={editData}
    />
  );
};

export default Training;
