// modSelectors.js

export const selectTrainingData = (state) => state.training.trainings; // Selector to get meals data
export const selectLoading = (state) => state.training.loading; // Selector to get loading state
export const selectTotal = (state) => state.training.total; // Selector for total pages
export const selectError = (state) => state.training.error; // Selector for error state
export const selectCurrentPage = (state) => state.training.page;
export const selectLimit = (state) => state.training.limit;
export const selectAvailableCoaches = (state) =>
  state.training.availableCoaches;
export const selectLevelDetails = (state) => state.training.levelData;
