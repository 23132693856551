import * as React from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReactComponent as DateIcon } from "../../assets/calendar.svg";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";
import CheckCircleIcon from "@mui/icons-material/Check";

function ButtonField(props) {
  const {
    setOpen,
    id,
    disabled,
    isDateIcon,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {}
  } = props;

  return isDateIcon ? (
    <IconButton
      sx={{
        height: "32px",
        width: "32px",
        backgroundColor: "#F3F4F6",
        borderRadius: "4px"
      }}
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      size="small"
    >
      <DateIcon fontSize="small" />
    </IconButton>
  ) : (
    <IconButton
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      sx={{
        backgroundColor: "#03B55C", // Green background
        color: "#fff", // White text
        "&:hover": {
          backgroundColor: "#45a049" // Darker green on hover
        },
        borderRadius: "50%"
      }}
    >
      <CheckCircleIcon height={32} width={32} fontSize="small" />
    </IconButton>
  );
}

function ButtonDatePicker(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <DatePicker
      slots={{ ...props.slots, field: ButtonField }}
      slotProps={{
        ...props.slotProps,
        field: { setOpen, isDateIcon: props.isDateIcon }
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default function PickerWithButtonField({
  date,
  setDate,
  disabledDates,
  handleUpdate,
  isDateIcon = true
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ButtonDatePicker
        label={date == null ? null : date.format("MM/DD/YYYY")}
        value={date}
        isDateIcon={isDateIcon}
        disablePast
        shouldDisableDate={(date) => {
          const formattedDate = dayjs(date).format("YYYY-MM-DD");
          return disabledDates.includes(formattedDate);
        }}
        onChange={async (newValue) => {
          setDate(newValue);
          await handleUpdate(newValue);
        }}
      />
    </LocalizationProvider>
  );
}
