import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllLevels,
  getTrainingData,
  getAllCoachesData,
  updateTraining,
  addNewTraining
} from "../../api/trainingApi";

// Async thunk for adding a new meal
export const addNewTrainings = createAsyncThunk(
  "mod/addNewTrainings",
  async (data, thunkAPI) => {
    try {
      const response = await addNewTraining(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTrainings = createAsyncThunk(
  "mod/updateTrainings",
  async ({ trainingData, levelId, trainingId }, thunkAPI) => {
    try {
      const response = await updateTraining(trainingData, levelId, trainingId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllTrainings = createAsyncThunk(
  "mod/getAllTrainings",
  async ({ page = 0, limit = 10, search, level }, thunkAPI) => {
    try {
      const response = await getTrainingData(page, limit, search, level);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllCoaches = createAsyncThunk(
  "mod/getAllCoaches",
  async (_, thunkAPI) => {
    try {
      const response = await getAllCoachesData();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllLevelDetails = createAsyncThunk(
  "mod/getAllLevelDetails",
  async (userId, thunkAPI) => {
    try {
      const response = await getAllLevels(userId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  trainings: [], // holds the meals data
  loading: false, // loading state for getAllMeals
  error: null, // error state
  total: 0,
  page: 0,
  availableCoaches: [],
  levelData: [],
  limit: 10
};

const traininglice = createSlice({
  name: "training",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Handling getAllMeals actions
      .addCase(getAllTrainings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTrainings.fulfilled, (state, action) => {
        state.loading = false;
        state.trainings = action.payload.trainings; // assuming response has meals data
        state.total = action.payload.totalTrainings; // total pages
      })
      .addCase(getAllTrainings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllCoaches.pending, (state) => {
        state.availableCoaches = [];
      })
      .addCase(getAllCoaches.fulfilled, (state, action) => {
        state.loading = false;
        state.availableCoaches = action.payload.users;
      })
      .addCase(getAllCoaches.rejected, (state, action) => {
        state.availableCoaches = [];
        state.error = action.payload;
      })
      .addCase(getAllLevelDetails.pending, (state) => {
        state.levelData = [];
      })
      .addCase(getAllLevelDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.levelData = action.payload.levels;
      })
      .addCase(getAllLevelDetails.rejected, (state, action) => {
        state.levelData = [];
        state.error = action.payload;
      });
  }
});

export const { setPage, setLimit } = traininglice.actions;

export default traininglice.reducer;
