import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  superAdminLogin,
  superAdminLogout,
  adminRegister,
  editUserProfile,
  addNewUserAdmin
} from "../../api/authApi";

// Thunks for handling async actions
export const loginUser = createAsyncThunk(
  "adminauth/login",
  async (credentials, thunkAPI) => {
    try {
      const response = await superAdminLogin(credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const registerUser = createAsyncThunk(
  "adminauth/register",
  async (userData, thunkAPI) => {
    try {
      const response = await adminRegister(userData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addNewUser = createAsyncThunk(
  "adminauth/addNewUser",
  async (userData, thunkAPI) => {
    try {
      const response = await addNewUserAdmin(userData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editUser = createAsyncThunk(
  "auth/editUser",
  async ({ formData, userId }, thunkAPI) => {
    try {
      const response = await editUserProfile(formData, userId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "adminauth/logout",
  async (_, thunkAPI) => {
    try {
      const response = await superAdminLogout();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  admin:
    JSON.parse(
      ["undefined", "null", undefined, null].includes(
        localStorage.getItem("admin")
      )
        ? null
        : localStorage.getItem("admin")
    ) || null,
  isAuthenticated: !!localStorage.getItem("adminToken"),
  loading: false,
  error: null
};

const adminauthSlice = createSlice({
  name: "adminauth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.admin = action.payload.user;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Register
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.admin = action.payload.user;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Logout
      .addCase(logoutUser.fulfilled, (state) => {
        state.admin = null;
        state.isAuthenticated = false;
      });
  }
});

export default adminauthSlice.reducer;
