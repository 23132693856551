const getInitials = (name) => {
  return name
    ?.split(" ")
    ?.map((name) => name[0])
    ?.join("")
    ?.slice(0, 2)
    ?.toUpperCase();
};

export default getInitials;
