import { S3Client } from "@aws-sdk/client-s3";

const s3Client = new S3Client({
  forcePathStyle: false,
  endpoint: process.env.REACT_APP_DO_SPACES_ENDPOINT,
  region: process.env.REACT_APP_DO_SPACES_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_DO_SPACES_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_DO_SPACES_SECRET_KEY
  }
});

export { s3Client };
