import createApi from "../utils/axiosInstance";

export const addNewModData = async (mealData) => {
  try {
    const api = createApi(true);

    const response = await api.post(`/admin/meal/add-meal`, mealData);
    return response.data;
  } catch (error) {
    console.error(
      "New mod data registeration failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getMealsData = async (
  currentDate,
  page,
  limit,
  search,
  status
) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/admin/meal/${status ? "get-meal-by-status" : "get-all-meals"}?${
        status ? `status=${status}&` : ""
      }date=${currentDate}&page=${page}&limit=${limit}&search=${search}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Get meals data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const updateDateOfSchedule = async (mealData, mealId) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/admin/meal/edit-meal/${mealId}`,
      mealData
    );
    return response.data;
  } catch (error) {
    console.error(
      "Update schedule date failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteMeal = async (mealId) => {
  try {
    const api = createApi(true);
    const response = await api.delete(`/admin/meal/delete-meal/${mealId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Delete meal failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
