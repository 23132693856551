import createApi from "../utils/axiosInstance";

export const addNewTraining = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/admin/training/create-training`, data);
    return response.data;
  } catch (error) {
    console.error(
      "New training data addition failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getTrainingData = async (page, limit, search, level) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/admin/training/training-by-level/${level}?page=${page}&limit=${limit}&search=${search}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Get training data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getAllCoachesData = async () => {
  try {
    const api = createApi(true);
    const response = await api.get(`/auth/admin/get-all-partners`);
    return response.data;
  } catch (error) {
    console.error(
      "Get training data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getAllLevels = async (userId) => {
  try {
    const api = createApi(true);
    const response = await api.get(`/admin/training/get-level/${userId || ""}`);
    return response.data;
  } catch (error) {
    console.error(
      "Get training data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const updateTraining = async (trainingData, levelId, trainingId) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/admin/training/edit-training-levels/${trainingId}/${levelId}`,
      trainingData
    );
    return response.data;
  } catch (error) {
    console.error(
      "Update  failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const updateCommunityLink = async (link, levelName, userId) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/admin/training/edit-level-link/${userId ? userId : ""}`,
      { link, levelName }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Update  failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const updateFAQforLevel = async (faq, levelName, userId) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/admin/training/edit-level-faq/${userId ? userId : ""}`,
      { faq, levelName }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Update  failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteTraining = async (trainingId) => {
  try {
    const api = createApi(true);
    const response = await api.delete(
      `/admin/training/delete-training/${trainingId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Delete training failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
