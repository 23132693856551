import createApi from "../utils/axiosInstance";

// Super admin login API
export const superAdminLogin = async (credentials) => {
  try {
    const api = createApi(false);
    const response = await api.post("/auth/admin/login", credentials);

    if (response.data) {
      const { user, token } = response.data;
      // const hours = expiresIn?.split("h")[0];
      // const expirationTime = new Date().getTime() + hours * 60 * 60 * 1000;
      localStorage.setItem("adminToken", token);
      localStorage.setItem("admin", JSON.stringify(user));
      // localStorage.setItem("expirationTime", expirationTime);
    }

    return response.data;
  } catch (error) {
    console.error(
      "Login failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

// Admin Register API
export const adminRegister = async (userData) => {
  try {
    const api = createApi(false);
    const { role, mainData } = userData;
    const response = await api.post(`/auth/admin/register/${role}`, mainData);
    if (response.data) {
      const { user, token } = response.data;
      // const hours = expiresIn?.split("h")[0];
      // const expirationTime = new Date().getTime() + hours * 60 * 60 * 1000;
      localStorage.setItem("adminToken", token);
      localStorage.setItem("admin", JSON.stringify(user));
      // localStorage.setItem("expirationTime", expirationTime);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Registration failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

// User Register by admin API
export const addNewUserAdmin = async (userData) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/auth/admin/add-user`, userData);
    return response.data;
  } catch (error) {
    console.error(
      "New user registeration failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const editUserProfile = async (userData, userId) => {
  try {
    const api = createApi(false);
    const response = await api.post(`/user/editProfile/${userId}`, userData);
    // if (response.data.user) {
    //   const { user, expiresIn, token } = response.data;
    //   const hours = expiresIn?.split("h")[0];
    //   const expirationTime = new Date().getTime() + hours * 60 * 60 * 1000;
    //   localStorage.setItem("token", token);
    //   localStorage.setItem("user", JSON.stringify(user));
    //   localStorage.setItem("expirationTime", expirationTime);
    // }
    return response.data;
  } catch (error) {
    console.error(
      "Completing profile failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};

// Super admin logout API
export const superAdminLogout = async () => {
  try {
    // Remove token from localStorage on logout
    localStorage.removeItem("adminToken");
    localStorage.removeItem("admin");
    // localStorage.removeItem("expirationTime");
    return { message: "Successfully logout" };
  } catch (error) {
    console.error(
      "Logout failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

// Fetch authenticated user
export const fetchUser = async () => {
  try {
    const api = createApi(false); // Token required
    const response = await api.get("/auth/me");
    return response.data;
  } catch (error) {
    console.error(
      "Fetching user data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const forgetPassword = async (email) => {
  try {
    const api = createApi(false);
    const response = await api.post("/auth/forgotPassword", email);

    localStorage.removeItem("adminToken");
    localStorage.removeItem("user");
    // localStorage.removeItem("expirationTime");
    return response.data;
  } catch (error) {
    console.error(
      "Forget password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const resetPassword = async (userData) => {
  try {
    const api = createApi(false);
    const response = await api.post("/auth/resetPassword", userData);
    return response.data;
  } catch (error) {
    console.log("error", error);
    console.error(
      "Reset Password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
