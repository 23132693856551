import { Box, Button, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as FilterIcon } from "../../assets/filter.svg";
import { ReactComponent as ImportIcon } from "../../assets/import.svg";
import { ReactComponent as ExportIcon } from "../../assets/export.svg";
import UsersTab from "../SubTabs/Users";
import AddUserModal from "../Modals/AddUser";
import OnlyUserTab from "./OnlyUsers";

const Users = ({ isAdmin = true }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [editData, setEditData] = React.useState(null);
  return (
    <Box>
      <Box display="flex" maxHeight="48px" justifyContent="space-between">
        <Box display="flex" gap={2}>
          <TextField
            variant="outlined" // You can change this to "filled" or "standard" as needed
            placeholder="Search..."
            size="small" // Adjust size if needed
            fullWidth // Makes the search bar take full width
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ height: "17px" }} />
                  </InputAdornment>
                )
              }
            }}
            sx={{
              "& .MuiInputBase-root": {
                padding: "14px 11px",
                height: "100%",
                //   width: "387px",
                borderRadius: 2
              },
              width: "387px",
              fontSize: "14px",
              background: "#FFFFFF", // Optional: set background color
              borderRadius: 2 // Optional: round corners
            }}
          />
          <Button
            sx={{
              fontSize: "14px",
              padding: "16px 24px",
              border: "1px solid #6B7280",
              color: "#6B7280",
              fontWeight: 700,
              textTransform: "none"
            }}
            variant="outlined"
            disableRipple
            startIcon={<FilterIcon />}
          >
            Filter
          </Button>
        </Box>
        <Box display="flex" gap={2}>
          <Button
            sx={{
              fontSize: "14px",
              padding: "16px 24px",
              border: "1px solid #38B65B",
              color: "#38B65B",
              fontWeight: 700,
              textTransform: "none"
            }}
            variant="outlined"
            startIcon={<ExportIcon />}
          >
            Export
          </Button>
          <Button
            sx={{
              fontSize: "14px",
              padding: "16px 24px",
              border: "1px solid #38B65B",
              color: "#38B65B",
              fontWeight: 700,
              textTransform: "none"
            }}
            variant="outlined"
            startIcon={<ImportIcon />}
          >
            Import
          </Button>
          <Button
            sx={{
              fontSize: "14px",
              padding: "16px 24px",
              background: "#38B65B",
              border: "1px solid #38B65B",
              color: "#fff",
              fontWeight: 700,
              textTransform: "none"
            }}
            onClick={() => setOpenModal(true)}
            variant="outlined"
          >
            Add User
          </Button>
        </Box>
      </Box>
      <AddUserModal
        editData={editData}
        open={openModal}
        isAdmin={isAdmin}
        onClose={() => {
          setOpenModal(false);
          setEditData(null);
        }}
      />
      {isAdmin ? (
        <UsersTab
          searchValue={searchValue}
          onEdit={(data) => {
            setOpenModal(true);
            setEditData(data);
          }}
          openModal={openModal}
        />
      ) : (
        <OnlyUserTab
          onEdit={(data) => {
            setOpenModal(true);
            setEditData(data);
          }}
          searchValue={searchValue}
          openModal={openModal}
        />
      )}
    </Box>
  );
};

export default Users;
