export const convertTextToLink = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g; // Regex pattern to match URLs with or without protocol
  const parts = text?.split(urlRegex); // Split the text by the URL pattern

  return parts.map((part, index) => {
    if (urlRegex.test(part)) {
      // If the part matches the URL pattern
      try {
        const url = part.startsWith("http") ? part : `http://${part}`; // Add protocol if missing
        const hostname = new URL(url).hostname; // Extract hostname from the URL
        const displayText = hostname.replace(/^www\./, "").split(".")[0]; // Take the first part of the hostname
        const linkText =
          displayText.charAt(0).toUpperCase() + displayText.slice(1);
        return (
          <a
            key={index}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#2C75C5" }}
          >
            {linkText}
          </a>
        );
      } catch (error) {
        return <span key={index}>{part}</span>; // If URL is invalid, return the original part as span
      }
    }
    return <span key={index}>{part}</span>; // Return non-URL text as span
  });
};
