// deleteFile.js
import { DeleteObjectCommand } from "@aws-sdk/client-s3";
import { s3Client } from "./s3Client";

export const deleteFile = async (fileName, folder = "meal-pictures") => {
  const command = new DeleteObjectCommand({
    Bucket: process.env.REACT_APP_DO_SPACES_BUCKET,
    Key: `${folder}/${fileName}`
  });

  try {
    await s3Client.send(command);
    return "File deleted successfully!";
  } catch (error) {
    console.error("Error deleting file:", error);
    throw new Error("File deletion failed");
  }
};
