import createApi from "../utils/axiosInstance";

export const getAllUsers = async (currentTab, page, limit, search) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/admin/user/get-all-users?page=${page}&limit=${limit}&search=${search}${
        currentTab ? `&type=${currentTab}` : ""
      }`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting user failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getUsersByType = async (page, limit, search) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/admin/user/get-admin-user-list?page=${page}&limit=${limit}&search=${search}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting user failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteUserHandler = async (userId) => {
  try {
    const api = createApi(true);
    const response = await api.delete(`/admin/user/delete-user/${userId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Getting user failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
