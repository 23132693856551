// useSignedUrl.js
import { useState, useEffect } from "react";
import { generateSignedUrl } from "./getPreSignedURL"; // Make sure the path is correct

const useSignedUrl = (fileName, folder) => {
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    if (!fileName || !folder) return;

    const fetchSignedUrl = async () => {
      try {
        const signedUrl = await generateSignedUrl(fileName, folder);
        setImgSrc(signedUrl);
      } catch (error) {
        console.error("Failed to fetch signed URL:", error);
      }
    };

    fetchSignedUrl();
  }, [fileName, folder]);

  return imgSrc;
};

export default useSignedUrl;
