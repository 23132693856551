import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import AllUsers from "../Tables/UserTable"; // Updated import
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllUsers,
  selectCurrentPage,
  selectLimit,
  selectTotalUsers,
  selectUsersError,
  selectUsersLoading
} from "../../features/users/userSelectors";
import { deleteUser, fetchUsersByType } from "../../features/users/userSlice";
import { ReactComponent as UsersIcon } from "../../assets/users.svg";
import { Close } from "@mui/icons-material";

const OnlyUserTab = ({ isAdmin, onEdit, openModal, searchValue }) => {
  const dispatch = useDispatch();

  const users = useSelector(selectAllUsers); // Now fetched from Redux
  const totalUsers = useSelector(selectTotalUsers);
  const page = useSelector(selectCurrentPage);
  const limit = useSelector(selectLimit);
  const loading = useSelector(selectUsersLoading);
  const error = useSelector(selectUsersError);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(null);

  const handleDelete = async () => {
    await dispatch(deleteUser(confirmDeleteModal));
    setConfirmDeleteModal(null);
  };

  useEffect(() => {
    if (!searchValue || searchValue?.length > 3) {
      dispatch(fetchUsersByType({ page, limit, searchValue }));
    }
  }, [dispatch, page, limit, openModal, searchValue, confirmDeleteModal]);

  return (
    <Box mt={4}>
      <Box
        height={"92px"}
        display="flex"
        gap="20px"
        marginTop={3}
        marginBottom={3}
      >
        <Box
          height={"100%"}
          width={"50%"}
          border="1px solid #D1D5DB"
          backgroundColor="#FCFCFC"
          borderRadius="6px"
        >
          <Box display="flex">
            <UsersIcon
              style={{ margin: "14px 16px 3px 16px" }}
              height={24}
              width={24}
            />
            <Typography
              margin="14px 16px 3px 16px"
              fontWeight={400}
              color="#6B7280"
              fontSize="15px"
            >
              Total available seats
            </Typography>
          </Box>
          <Typography margin={"0 16px"} fontSize="30px" fontWeight={600}>
            100
          </Typography>
        </Box>
        <Box
          height={"100%"}
          width={"50%"}
          border="1px solid #D1D5DB"
          backgroundColor="#FCFCFC"
          borderRadius="6px"
        >
          <Box display="flex">
            <UsersIcon
              style={{ margin: "14px 16px 3px 16px" }}
              height={24}
              width={24}
            />
            <Typography
              margin="14px 16px 3px 16px"
              fontWeight={400}
              color="#6B7280"
              fontSize="15px"
            >
              Occupied seats
            </Typography>
          </Box>
          <Typography margin={"0 16px"} fontSize="30px" fontWeight={600}>
            {totalUsers ? totalUsers : "-"}
          </Typography>
        </Box>
      </Box>
      {users?.length ? (
        <AllUsers
          totalUsers={totalUsers}
          users={users}
          onEdit={onEdit}
          loading={loading}
          isAdmin={false}
          error={error}
          setConfirmDeleteModal={setConfirmDeleteModal}
        />
      ) : (
        <></>
      )}
      <Modal
        open={!!confirmDeleteModal}
        onClose={() => setConfirmDeleteModal(null)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            width: { xs: "90%", sm: "550px" } // Responsive width
          }}
        >
          {/* Close button */}
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setConfirmDeleteModal(null)}>
              <Close />
            </IconButton>
          </Box>

          {/* Modal title */}
          <Box mb={3} px={2} textAlign="center">
            <Typography fontSize={"24px"} fontWeight={600}>
              Are you sure you want to delete?
            </Typography>
            <Typography fontSize={"14px"} color="text.secondary" mt={1}>
              This change cannot be undone and may impact users.
            </Typography>
          </Box>

          {/* Action buttons */}
          <Box display="flex" justifyContent="space-between" px={2} gap={2}>
            {/* Cancel Button */}
            <Button
              variant="outlined"
              onClick={() => setConfirmDeleteModal(null)}
              sx={{
                flex: 1,
                padding: "10px 0",
                borderColor: "grey.400",
                color: "grey.600",
                "&:hover": {
                  backgroundColor: "grey.100",
                  borderColor: "grey.500"
                }
              }}
            >
              Cancel
            </Button>

            {/* Delete Button */}
            <Button
              variant="contained"
              color="error"
              onClick={handleDelete} // Assuming you have this delete function
              sx={{
                flex: 1,
                padding: "10px 0",
                color: "white",
                background: "#E94545",
                "&:hover": {
                  backgroundColor: "error.dark"
                }
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default OnlyUserTab;
