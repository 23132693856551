import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addNewModData,
  getMealsData,
  updateDateOfSchedule,
  deleteMeal
} from "../../api/modApi";

// Async thunk for adding a new meal
export const addNewMod = createAsyncThunk(
  "mod/addNewMealOfDay",
  async (mealData, thunkAPI) => {
    try {
      const response = await addNewModData(mealData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateScheduleDate = createAsyncThunk(
  "mod/updateScheduleDateOfMeal",
  async ({ mealData, mealId }, thunkAPI) => {
    try {
      const response = await updateDateOfSchedule(mealData, mealId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteScheduledMeal = createAsyncThunk(
  "mod/deleteMeal",
  async ({ mealId }, thunkAPI) => {
    try {
      const response = await deleteMeal(mealId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Async thunk for fetching all meals with pagination
export const getAllMeals = createAsyncThunk(
  "mod/getAllMeals",
  async ({ currentDate, page, limit, search, status }, thunkAPI) => {
    try {
      const response = await getMealsData(
        currentDate,
        page,
        limit,
        search,
        status
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  mealData: [], // holds the meals data
  loading: false, // loading state for getAllMeals
  error: null, // error state
  total: 0,
  page: 0,
  availableDates: [],
  limit: 10
};

const modSlice = createSlice({
  name: "mod",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Handling addNewMod actions
      // .addCase(addNewMod.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(addNewMod.fulfilled, (state, action) => {
      //   state.loading = false;
      //   // Assuming that addNewMod will return an updated meal list, update the state
      //   state.mealData = action.payload;
      // })
      // .addCase(addNewMod.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })

      // Handling getAllMeals actions
      .addCase(getAllMeals.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMeals.fulfilled, (state, action) => {
        state.loading = false;
        state.mealData = action.payload.meals; // assuming response has meals data
        state.total = action.payload.totalMeals; // total pages

        if (action.payload.scheduledDates) {
          state.availableDates = action.payload.scheduledDates;
        } else {
          state.availableDates = Array.from(new Set([...state.availableDates]));
        }
      })
      .addCase(getAllMeals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setPage, setLimit } = modSlice.actions;

export default modSlice.reducer;
