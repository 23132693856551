// getSignedUrlHelper.js
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { s3Client } from "./s3Client";

export const generateSignedUrl = async (
  fileName,
  folder = "meal-pictures",
  expiresIn = 600
) => {
  const command = new GetObjectCommand({
    Bucket: process.env.REACT_APP_DO_SPACES_BUCKET,
    Key: `${folder}/${fileName}`
  });

  try {
    const url = await getSignedUrl(s3Client, command, { expiresIn });
    return url;
  } catch (error) {
    console.error("Error generating signed URL:", error);
    throw new Error("Failed to generate signed URL");
  }
};
