// uploadFile.js
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { s3Client } from "./s3Client";

export const uploadFile = async (file, filename, folder = "meal-pictures") => {
  if (!file) {
    throw new Error("No file provided");
  }

  const params = {
    Bucket: process.env.REACT_APP_DO_SPACES_BUCKET,
    Key: `${folder}/${filename}`,
    Body: file,
    ACL: "private",
    ContentType: file.type
  };

  try {
    await s3Client.send(new PutObjectCommand(params));
    return "File uploaded successfully!";
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("File upload failed");
  }
};
