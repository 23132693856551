import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as FilterIcon } from "../../assets/filter.svg";
import ModTab from "../SubTabs/ModTab";
import AddMod from "../Modals/AddMod";
import {
  deleteScheduledMeal,
  setLimit,
  setPage
} from "../../features/mod/modSlice";
import { useDispatch } from "react-redux";
import { Close } from "@mui/icons-material";

const Mod = ({ setIsAddingMeal, isAddingMeal }) => {
  const [searchValue, setSearchValue] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [editData, setEditData] = useState(null);
  const [confirmDeleteMealModal, setConfirmDeleteMealModal] = useState("");
  const [value, setValue] = useState(0);
  const [currentTab, setCurrentTab] = useState("Scheduled");
  const [isRefresh, setIsRefresh] = React.useState(false);
  const dispatch = useDispatch();

  const handleDelete = async (id) => {
    await dispatch(
      deleteScheduledMeal({
        mealId: id
      })
    );
    setConfirmDeleteMealModal("");
    setTimeout(() => setIsRefresh(true), 200);
  };

  return !isAddingMeal ? (
    <Box>
      <Box display="flex" maxHeight="48px" justifyContent="space-between">
        <Box display="flex" gap={2}>
          <TextField
            variant="outlined" // You can change this to "filled" or "standard" as needed
            placeholder="Search..."
            size="small" // Adjust size if needed
            fullWidth // Makes the search bar take full width
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ height: "17px" }} />
                  </InputAdornment>
                )
              }
            }}
            sx={{
              "& .MuiInputBase-root": {
                padding: "14px 11px",
                height: "100%",
                //   width: "387px",
                borderRadius: 2
              },
              width: "387px",
              fontSize: "14px",
              background: "#FFFFFF", // Optional: set background color
              borderRadius: 2 // Optional: round corners
            }}
          />
          <Button
            sx={{
              fontSize: "14px",
              padding: "16px 24px",
              border: "1px solid #6B7280",
              color: "#6B7280",
              fontWeight: 700,
              textTransform: "none"
            }}
            variant="outlined"
            disableRipple
            startIcon={<FilterIcon />}
          >
            Filter
          </Button>
        </Box>
        <Box display="flex" gap={2}>
          <Button
            sx={{
              fontSize: "14px",
              padding: "16px 24px",
              background: "#38B65B",
              border: "1px solid #38B65B",
              color: "#fff",
              fontWeight: 700,
              textTransform: "none"
            }}
            onClick={() => {
              setIsAddingMeal(true);
              setScheduleDate("");
              setEditData(null);
              dispatch(setPage(0));
              dispatch(setLimit(10));
            }}
            variant="outlined"
          >
            Add a meal
          </Button>
        </Box>
      </Box>
      <Modal
        open={!!confirmDeleteMealModal}
        onClose={() => setConfirmDeleteMealModal(null)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            width: { xs: "90%", sm: "550px" } // Responsive width
          }}
        >
          {/* Close button */}
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setConfirmDeleteMealModal(null)}>
              <Close />
            </IconButton>
          </Box>

          {/* Modal title */}
          <Box mb={3} px={2} textAlign="center">
            <Typography fontSize={"24px"} fontWeight={600}>
              Are you sure you want to delete?
            </Typography>
            <Typography fontSize={"14px"} color="text.secondary" mt={1}>
              This change cannot be undone and may impact users.
            </Typography>
          </Box>

          {/* Action buttons */}
          <Box display="flex" justifyContent="space-between" px={2} gap={2}>
            {/* Cancel Button */}
            <Button
              variant="outlined"
              onClick={() => setConfirmDeleteMealModal(null)}
              sx={{
                flex: 1,
                padding: "10px 0",
                borderColor: "grey.400",
                color: "grey.600",
                "&:hover": {
                  backgroundColor: "grey.100",
                  borderColor: "grey.500"
                }
              }}
            >
              Cancel
            </Button>

            {/* Delete Button */}
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete(confirmDeleteMealModal)} // Assuming you have this delete function
              sx={{
                flex: 1,
                padding: "10px 0",
                color: "white",
                background: "#E94545",
                "&:hover": {
                  backgroundColor: "error.dark"
                }
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <ModTab
        isRefresh={isRefresh}
        setIsRefresh={setIsRefresh}
        value={value}
        setValue={setValue}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        setScheduleDate={setScheduleDate}
        searchValue={searchValue}
        isAddingMeal={isAddingMeal}
        setIsAddingMeal={setIsAddingMeal}
        setEditData={setEditData}
        setConfirmDeleteMealModal={setConfirmDeleteMealModal}
      />
      {/*  <AddUserModal open={openModal} onClose={() => setOpenModal(false)} /> */}
    </Box>
  ) : (
    <AddMod
      currentTab={currentTab}
      scheduleDate={scheduleDate}
      setIsAddingMeal={setIsAddingMeal}
      editData={editData}
    />
  );
};

export default Mod;
